
import { defineComponent, onMounted, ref } from "vue";
import { ApiBase } from "@/core/api";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { TaggingItem } from "@/core/directive/interface/common";
import { WarehouseInventoryStatus } from "@/core/directive/type/warehouseInventory";

interface Filter {
  aggregateViewStatus: string[];
  singleViewStatus: string[];
  brand_id: string;
  name: string;
  brand_article_no: string;
  sale_order_number: string;
  inventory_date_start: string;
  inventory_date_end: string;
}

export default defineComponent({
  name: "filter-warehouse-inventory-dropdown",
  props: {
    option: { type: Object, required: true },
    warehouseInventoryType: { type: String, default: "" },
  },
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const formData = ref<Filter>({
      aggregateViewStatus: [
        String(WarehouseInventoryStatus.Locked),
        String(WarehouseInventoryStatus.Incoming),
        String(WarehouseInventoryStatus.Available),
        String(WarehouseInventoryStatus.Reserved),
      ],
      singleViewStatus: [
        String(WarehouseInventoryStatus.Locked),
        String(WarehouseInventoryStatus.Incoming),
        String(WarehouseInventoryStatus.Available),
      ],
      brand_id: "",
      name: "",
      brand_article_no: "",
      sale_order_number: "",
      inventory_date_start: "",
      inventory_date_end: "",
    });

    const options = ref({
      brandLoading: false,
      brand: [] as TaggingItem[],
    });

    const getBrandSourceData = async (value, id?) => {
      options.value.brandLoading = true;
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getBrandSourceData(params);
      options.value.brandLoading = false;
      if (data.code == 0) {
        options.value.brand = data.data;
      }
    };

    const debounceBrandSourceSearch = _.debounce(getBrandSourceData, 1000);

    const searchBrandSourceItems = (query: string) => {
      debounceBrandSourceSearch(query);
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      getBrandSourceData("");
    });

    return {
      props,
      t,
      formData,
      formRef,
      submit,
      handleReset,
      options,
      searchBrandSourceItems,
    };
  },
});
