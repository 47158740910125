
import { defineComponent, onMounted, ref, watch } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { CommonFormType, CommonHtmlType } from "@/core/directive/type/common";
import {
  formatDate,
  modalShowListener,
} from "@/core/directive/function/common";
import { TaggingItem } from "@/core/directive/interface/common";
import { ApiWarehouseInventory } from "@/core/api";
import {
  warehouseInventorySingleItem,
  WarehouseInventorySingleItem,
} from "@/core/directive/interface/warehouseInventory";
import { WarehouseInventoryStatus } from "@/core/directive/type/warehouseInventory";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import { commonGalleryImages } from "@/core/directive/function/order";

export default defineComponent({
  name: "order-assign-merchant-order-quantity-change",
  components: { ProductInfo, SkuInfo },
  props: {
    option: { type: Object, required: true },
    itemData: {
      type: Object as () => WarehouseInventorySingleItem,
      default: () => {
        return Object.assign({}, warehouseInventorySingleItem);
      },
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const formRef = ref<CommonFormType>(null);
    const updateInventoryRef = ref<CommonHtmlType>(null);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      warehouse_id: "",
      inventory_target: "",
      inventory_type: "",
      status: "",
    });

    const rules = ref({
      warehouse_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      inventory_target: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      inventory_type: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      status: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({
      defaultWarehouse: [] as TaggingItem[],
      warehouse: [] as TaggingItem[],
    });

    watch(
      () => props.option.warehouse,
      (newValue) => {
        if (newValue) {
          options.value.defaultWarehouse = props.option.warehouse.filter(
            (item) => {
              return (
                item.value === 2 ||
                item.value === 3 ||
                item.value === 4 ||
                item.value === 11 ||
                item.value === 14 ||
                item.value === 15
              );
            }
          );
        }
      }
    );

    watch(
      () => props.itemData.warehouse_id,
      (newValue) => {
        if (newValue) {
          if (
            newValue !== 2 &&
            newValue !== 3 &&
            newValue !== 4 &&
            newValue !== 11 &&
            newValue !== 14 &&
            newValue !== 15
          ) {
            options.value.warehouse = props.option.warehouse.filter((item) => {
              return (
                item.value === 2 ||
                item.value === 3 ||
                item.value === 4 ||
                item.value === 11 ||
                item.value === 14 ||
                item.value === 15
              );
            });
          } else {
            options.value.warehouse = [];
          }
        } else {
          options.value.warehouse = [];
        }
      }
    );

    const getFormInfo = () => {
      // loading.value = true;
      // Promise.all([])
      //   .then(() => {
      //     loading.value = false;
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          const { data } = await ApiWarehouseInventory.inventoryRefundToSpot({
            id: props.itemData.id,
            ...formData.value,
          });
          loading.value = false;
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              emit("update-list");
              hideModal(updateInventoryRef.value);
            });
          } else {
            showServerErrorMsg(data);
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    const init = () => {
      setTimeout(() => {
        formData.value.status = String(props.itemData.status);
        formData.value.inventory_target = String(
          props.itemData.inventory_target
        );
        formData.value.inventory_type =
          props.itemData.inventory_type === 0
            ? ""
            : String(props.itemData.inventory_type);
        formData.value.warehouse_id = props.itemData.warehouse_id as string;
      }, 0);
    };

    const handleDiscard = () => {
      hideModal(updateInventoryRef.value);
    };

    onMounted(() => {
      modalShowListener(updateInventoryRef.value, () => {
        init();
      });
      modalHideListener(updateInventoryRef.value, () => {
        resetForm();
      });
      getFormInfo();
    });

    return {
      t,
      formatDate,
      commonGalleryImages,
      WarehouseInventoryStatus,
      props,
      updateInventoryRef,
      loading,
      formData,
      formRef,
      options,
      rules,
      handleDiscard,
      submit,
      resetForm,
    };
  },
});
