/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-05-05 09:59:54
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-05-05 10:23:23
 * @FilePath: /fulfillment-frontend/src/core/directive/interface/warehouseInventory.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { NumberOrString } from "../type/common";

export const warehouseInventorySingleItem: WarehouseInventorySingleItem = {
  id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  created_uid: 0,
  updated_uid: 0,
  deleted_uid: 0,
  status: 0,
  stock_in_id: "",
  sku_identifier: "",
  identification_code: "",
  inventory_type: 0,
  inventory_target: 0,
  warehouse_id: 0,
  merchant_id: 0,
  channel_id: 0,
  owner: 0,
  fulfillment_order_number: "",
  sale_order_number: "",
  __show: {
    id: 0,
    product_identifier_id: "",
    inventory_date: "",
    sale_order_number: "",
    primary_image: "",
    images: [],
    brand: "",
    name: "",
    name_en: "",
    brand_article_no: "",
    size: "",
    color: "",
    warehouse_name: "",
    shelf: "",
    shelf_id: 0,
    sku_inventory_type: "",
    stock_in_inventory_target: "",
    warehouse_inventory_status: "",
  },
  relation_warehouse_stock_in_sku: {
    id: 0,
    created_at: "",
    updated_at: "",
    deleted_at: "",
    created_uid: 0,
    updated_uid: 0,
    deleted_uid: 0,
    stock_in_id: "",
    sku_identifier: "",
    spu: "",
    sku_gtin: "",
    merchant_article_no: "",
    color: "",
    size: "",
    received_quantity: 0,
    passed_quantity: 0,
    failed_quantity: 0,
    origin_country: "",
    brand_id: 0,
    brand_name: "",
    name: "",
    name_en: "",
    brand_article_no: "",
    primary_image: "",
    images: [],
    capacity: "",
  },
};

export interface WarehouseInventorySingleItem {
  id: NumberOrString;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  created_uid: NumberOrString;
  updated_uid: NumberOrString;
  deleted_uid: NumberOrString;
  status: NumberOrString;
  stock_in_id: string;
  sku_identifier: string;
  identification_code: string;
  inventory_type: NumberOrString;
  inventory_target: NumberOrString;
  warehouse_id: NumberOrString;
  merchant_id: NumberOrString;
  channel_id: NumberOrString;
  owner: NumberOrString;
  fulfillment_order_number: string;
  sale_order_number: string;
  __show: WarehouseInventorySingleItemShow;
  relation_warehouse_stock_in_sku: WarehouseInventorySingleItemRelationWarehouseStockInSku;
}

export interface WarehouseInventorySingleItemShow {
  id: NumberOrString;
  product_identifier_id: string;
  inventory_date: string;
  sale_order_number: string;
  primary_image: string;
  images: string[];
  brand: string;
  name: string;
  name_en: string;
  brand_article_no: string;
  size: string;
  color: string;
  warehouse_name: string;
  shelf: string;
  shelf_id: NumberOrString;
  sku_inventory_type: string;
  stock_in_inventory_target: string;
  warehouse_inventory_status: string;
}

export interface WarehouseInventorySingleItemRelationWarehouseStockInSku {
  id: NumberOrString;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  created_uid: NumberOrString;
  updated_uid: NumberOrString;
  deleted_uid: NumberOrString;
  stock_in_id: string;
  sku_identifier: string;
  spu: string;
  sku_gtin: string;
  merchant_article_no: string;
  color: string;
  size: string;
  received_quantity: number;
  passed_quantity: number;
  failed_quantity: number;
  origin_country: string;
  brand_id: NumberOrString;
  brand_name: string;
  name: string;
  name_en: string;
  brand_article_no: string;
  primary_image: string;
  images: string[];
  capacity: string;
}
