/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-12-05 13:10:31
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-12-05 13:15:06
 */
import { TextStyle } from "../type/common";
import { WarehouseInventoryStatus } from "../type/warehouseInventory";

/**
 * @description: 获取订单状态（新）
 * @return {*}
 */
export const getWarehouseInventoryStatusMap = (
  status: number
): TextStyle | undefined => {
  const result = new Map([
    [WarehouseInventoryStatus.Locked, TextStyle.Secondary],
    [WarehouseInventoryStatus.Incoming, TextStyle.Warning],
    [WarehouseInventoryStatus.Available, TextStyle.Success],
    [WarehouseInventoryStatus.Reserved, TextStyle.Info],
    [WarehouseInventoryStatus.StockOut, TextStyle.Dark],
  ]);
  return result.get(status);
};
