
import { defineComponent, ref, onMounted, onActivated, computed } from "vue";
import { MBDatatablePlus } from "magicbean-saas-common";
import FilterDropdown from "@/views/warehouse-management/warehouse-inventory/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import {
  ApiBase,
  ApiFulfillmentOrders,
  ApiWarehouseInventory,
} from "@/core/api";
import _ from "lodash";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { SalesOrderTable } from "@/core/directive/interface/salesOrder";
import {
  FilterOption,
  SortOption,
  TaggingItem,
} from "@/core/directive/interface/common";
import store from "@/store";
import {
  CommonArrayToString,
  commonChangeFilterAddDate,
  commonExportFile,
  formatDate,
  formatDateTime,
  getDiffDays,
  getLateDays,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import JwtService from "@/core/services/JwtService";
import { getWarehouseInventoryStatusMap } from "@/core/directive/function/warehouseInventory";
import UpdateInventory from "./UpdateInventory.vue";
import { WarehouseInventoryStatus } from "@/core/directive/type/warehouseInventory";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import AddStockIn from "../stock-in/AddStockIn.vue";
import { Mutations } from "@/store/enums/StoreEnums";
import { commonGalleryImages } from "@/core/directive/function/order";
import { warehouseInventorySingleItem } from "@/core/directive/interface/warehouseInventory";

interface IHeaderConfiguration {
  name?: string;
  key: string;
  sortingField?: string;
  sortable?: boolean;
  align?: string;
}

export default defineComponent({
  name: "warehouse-management-warehouse-inventory",
  components: {
    MBDatatablePlus,
    FilterDropdown,
    UpdateInventory,
    ProductInfo,
    SkuInfo,
    AddStockIn,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const { showServerErrorMsg } = mixin();

    const loading = ref(true);
    const tableData = ref<Array<SalesOrderTable>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(
      store.getters.currentConfigPageSize(route.name)
    );
    const search = ref<string>("");
    const disabledImportOrders = ref<boolean>(false);
    const disabledExportSalesProfilling = ref<boolean>(false);
    const disabledExportOrderCombination = ref<boolean>(false);
    const disabledExportSalesOrderItemList = ref<boolean>(false);
    const disabledExportSalesFinancial = ref<boolean>(false);
    const disabledExport = ref<boolean>(false);
    const disabledExportSku = ref<boolean>(false);

    const options = ref({
      available_sku: 0,
      available_total: 0,
      itemData: Object.assign({}, warehouseInventorySingleItem),
      status_value_one: [
        String(WarehouseInventoryStatus.Locked),
        String(WarehouseInventoryStatus.Incoming),
        String(WarehouseInventoryStatus.Available),
        String(WarehouseInventoryStatus.Reserved),
      ],
      status_value_two: [
        String(WarehouseInventoryStatus.Locked),
        String(WarehouseInventoryStatus.Incoming),
        String(WarehouseInventoryStatus.Available),
      ],
      warehouseInventoryType: "1",
      warehouseInventory: [
        {
          label: t("warehouseInventory.aggregateView"),
          value: "1",
        },
        {
          label: t("warehouseInventory.singleView"),
          value: "2",
        },
      ],
      warehouse_id: "",
      warehouse: [] as TaggingItem[],
      warehouseOptions: [] as TaggingItem[],
      inventoryTypeOptions: [] as TaggingItem[],
      inventoryTargetOptions: [] as TaggingItem[],
      inventoryStatusOptions: [] as TaggingItem[],
    });

    const filterOptions = ref<Array<FilterOption>>([]);

    const getWarehouseFilter = computed(() => {
      let filterArr: FilterOption[] = [];
      if (options.value.warehouse_id) {
        filterArr.push({
          field: "warehouse_id",
          value: options.value.warehouse_id,
          condition: "eq",
        });
      }
      return filterArr;
    });

    const getStatusFilter = computed(() => {
      let filterArr: FilterOption[] = [];
      let filterOption: FilterOption[] = filterOptions.value;
      let statusIndex = filterOption.findIndex(
        (item) => item.field === "status"
      );
      if (statusIndex === -1) {
        if (options.value.warehouseInventoryType === "1") {
          filterArr.push({
            field: "status",
            value: options.value.status_value_one,
            condition: "in",
          });
        } else {
          filterArr.push({
            field: "status",
            value: options.value.status_value_two,
            condition: "in",
          });
        }
      }
      return filterArr;
    });

    const sortOptions = ref<Array<SortOption>>([
      { field: "created_at", direction: "desc" },
    ]);

    const tableHeader = computed(() => {
      let arr: IHeaderConfiguration[] = [];
      if (options.value.warehouseInventoryType === "1") {
        arr = [
          // Aggregate
          {
            name: t("order.article"),
            key: "article",
            sortable: false,
          },
          {
            name: t("order.sku"),
            key: "sku",
            sortable: false,
          },
          {
            name: t("warehouse.warehouse"),
            key: "warehouse",
            sortable: false,
          },
          {
            name: t("warehouseInventory.incoming"),
            key: "incoming_qty",
            align: "right",
            sortable: false,
          },
          {
            name: t("warehouseInventory.reserved"),
            key: "reserved_qty",
            align: "right",
            sortable: false,
          },
          {
            name: t("warehouseInventory.locked"),
            key: "locked_qty",
            align: "right",
            sortable: false,
          },
          {
            name: t("warehouseInventory.available"),
            key: "available_qty",
            align: "right",
            sortable: false,
          },
        ];
      } else {
        arr = [
          {
            name: t("order.guid"),
            key: "guid",
            sortable: false,
          },
          {
            name: t("warehouseInventory.stockedDate"),
            key: "stocked_date",
            sortable: false,
          },
          {
            name: t("order.article"),
            key: "article",
            sortable: false,
          },
          {
            name: t("order.sku"),
            key: "sku",
            sortable: false,
          },
          {
            name: t("warehouse.warehouse"),
            key: "warehouse",
            sortable: false,
          },
          {
            name: t("warehouseInventory.type"),
            key: "sku_inventory_type",
            sortable: false,
          },
          {
            name: t("warehouseInventory.inventoryTarget"),
            key: "stock_in_inventory_target",
            sortable: false,
          },
          {
            name: t("warehouseInventory.status"),
            key: "warehouse_inventory_status",
            sortable: false,
          },
          {
            name: t("common.action"),
            key: "actions",
            sortable: false,
          },
        ];
      }
      return arr;
    });

    const init = () => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getWarehouseInventoryList();
      if (options.value.warehouse.length === 0) {
        getWarehouseData();
      }
      if (
        options.value.inventoryTargetOptions.length === 0 ||
        options.value.inventoryStatusOptions.length === 0
      ) {
        getTaggingData();
      }
    };

    const getWarehouseData = async () => {
      const { data } = await ApiFulfillmentOrders.getWarehouseData({});
      if (data.code == 0) {
        let arr = [
          {
            label: t("warehouseInventory.allWarehouse"),
            value: "",
          },
        ];
        arr.push(...data.data);
        options.value.warehouse = arr;
        options.value.warehouseOptions = data.data;
      }
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "sku_inventory_type",
          "stock_in_inventory_target",
          "warehouse_inventory_status",
        ],
      });
      if (data.code == 0) {
        options.value.inventoryTypeOptions = data.data.sku_inventory_type.items;
        options.value.inventoryTargetOptions =
          data.data.stock_in_inventory_target.items;
        options.value.inventoryStatusOptions =
          data.data.warehouse_inventory_status.items;
      }
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    const fileExport = () => {
      if (disabledExport.value) return false;
      if (options.value.warehouseInventoryType === "1") {
        exportAggregate();
      } else {
        exportSingle();
      }
    };

    const exportAggregate = async () => {
      disabledExport.value = true;
      const data = await ApiWarehouseInventory.exportAggregate({
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        filter_group: [
          ...getWarehouseFilter.value,
          ...getStatusFilter.value,
          ...filterOptions.value,
        ],
        sort_orders: sortOptions.value,
      });
      disabledExport.value = false;
      commonExportFile(data);
    };

    const exportSingle = async () => {
      disabledExport.value = true;
      const data = await ApiWarehouseInventory.exportSingle({
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        filter_group: [
          ...getWarehouseFilter.value,
          ...getStatusFilter.value,
          ...filterOptions.value,
        ],
        sort_orders: sortOptions.value,
      });
      disabledExport.value = false;
      commonExportFile(data);
    };

    const getWarehouseInventoryList = async () => {
      // const { data } = await ApiWarehouseInventory.getWarehouseInventoryList({
      //   page: page,
      //   page_size: pageSize,
      //   search_value: search,
      //   filter_group: filterOptions,
      //   sort_orders: sortOptions,
      // });
      // loading.value = false;
      // if (data.code == 0) {
      //   tableData.value.splice(0, tableData.value.length, ...data.data.items);
      //   total.value = data.data.total;
      // }
      if (options.value.warehouseInventoryType === "1") {
        await getAggregateInventoryList();
      } else {
        await getSingleInventoryList();
      }
    };

    const getAggregateInventoryList = async () => {
      loading.value = true;
      const { data } = await ApiWarehouseInventory.getAggregateInventoryList({
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        filter_group: [
          ...getWarehouseFilter.value,
          ...getStatusFilter.value,
          ...filterOptions.value,
        ],
        sort_orders: sortOptions.value,
      });
      loading.value = false;
      if (data.code == 0) {
        tableData.value.splice(0, tableData.value.length, ...data.data.items);
        total.value = data.data.total;
        options.value.available_sku = data.data.available_sku;
        options.value.available_total = data.data.available_total;
      }
    };

    const getSingleInventoryList = async () => {
      loading.value = true;
      const { data } = await ApiWarehouseInventory.getSingleInventoryList({
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        filter_group: [
          ...getWarehouseFilter.value,
          ...getStatusFilter.value,
          ...filterOptions.value,
        ],
        sort_orders: sortOptions.value,
      });
      loading.value = false;
      if (data.code == 0) {
        tableData.value.splice(0, tableData.value.length, ...data.data.items);
        total.value = data.data.total;
        options.value.available_sku = data.data.available_sku;
        options.value.available_total = data.data.available_total;
      }
    };

    const updateList = () => {
      getWarehouseInventoryList();
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getWarehouseInventoryList();
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getWarehouseInventoryList();
    };

    const filterChange = (e) => {
      searchItems();
    };

    const updateItem = (item) => {
      options.value.itemData = item;
    };

    const resetItem = () => {
      options.value.itemData = Object.assign({}, warehouseInventorySingleItem);
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (item == "status") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "in",
            });
          } else if (item == "inventory_date_start") {
            filterArr.push({
              field: "inventory_date",
              value: commonChangeFilterAddDate(val[item], 1),
              condition: "gteq",
            });
          } else if (item == "inventory_date_end") {
            filterArr.push({
              field: "inventory_date",
              value: commonChangeFilterAddDate(val[item]),
              condition: "lteq",
            });
          } else if (item == "aggregateViewStatus") {
            if (options.value.warehouseInventoryType == "1") {
              filterArr.push({
                field: "status",
                value: val[item],
                condition: "in",
              });
            }
          } else if (item == "singleViewStatus") {
            if (options.value.warehouseInventoryType == "2") {
              filterArr.push({
                field: "status",
                value: val[item],
                condition: "in",
              });
            }
          } else if (item == "brand_id") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          } else if (item == "name") {
            filterArr.push({
              field: "name",
              value: val[item],
              condition: "like",
            });
            filterArr.push({
              field: "name_en",
              value: val[item],
              condition: "like",
            });
          } else if (item == "brand_article_no") {
            filterArr.push({
              field: "brand_article_no",
              value: val[item],
              condition: "like",
            });
            filterArr.push({
              field: "merchant_article_no",
              value: val[item],
              condition: "like",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getWarehouseInventoryList();
    };

    const handleFilterReset = () => {
      resetFilter();
      getWarehouseInventoryList();
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getWarehouseInventoryList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch();
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const getName = computed(() => {
      return (row) => {
        if (!row) return "";
        return JwtService.getLang() === "zh_CN" ? row.name : row.name_en;
      };
    });

    return {
      t,
      getDiffDays,
      getLateDays,
      CommonArrayToString,
      formatDate,
      formatDateTime,
      WarehouseInventoryStatus,
      getWarehouseInventoryStatusMap,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      disabledImportOrders,
      disabledExportSalesProfilling,
      disabledExportOrderCombination,
      disabledExportSalesOrderItemList,
      disabledExportSalesFinancial,
      disabledExport,
      disabledExportSku,
      options,
      commonGalleryImages,
      getWarehouseInventoryList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      filterChange,
      updateItem,
      resetItem,
      handleFilter,
      handleFilterReset,
      updateList,
      removeSearch,
      getName,
      fileExport,
    };
  },
});
