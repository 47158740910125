/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-12-05 13:08:01
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-01-13 15:07:44
 */
export enum WarehouseInventoryStatus {
  Locked,
  Incoming = 5,
  Available = 10,
  Reserved = 20,
  StockOut = 30,
}

export enum WarehouseInventorySpotLabelType {
  Incoming = 10,
  Specialty = 20,
}
