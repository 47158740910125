
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { ApiBase, ApiMerchant, ApiStock } from "@/core/api";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import FsLightbox from "fslightbox-vue/v3";
import { ChannelOption, TaggingItem } from "@/core/directive/interface/common";
import {
  ProductItem,
  ProductItemImage,
} from "@/core/directive/interface/order";
import { previewOrderImages } from "@/core/directive/function/order";
import {
  CommonFormType,
  CommonHtmlType,
  NumberOrString,
} from "@/core/directive/type/common";
import { formatDateTime, showModal } from "@/core/directive/function/common";
import _ from "lodash";
import CommonChannelOption from "@/components/layout/CommonChannelOption.vue";

export default defineComponent({
  name: "choose-product",
  components: {
    FsLightbox,
    CommonChannelOption,
  },
  props: {
    submitData: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const formRef = ref<CommonFormType>(null);
    const chooseProduct = ref<CommonHtmlType>(null);
    const submitButton = ref<CommonHtmlType>(null);
    const isHide = ref<boolean>(false);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      stock_in_ids: [] as number[],
      merchant: "",
      channel: "",
    });

    const rules = ref({
      merchant: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      channel: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({
      list: [],
      stock_in_list: [] as any[],
      product_list: [] as ProductItem[],
      submitData: {},
      loadingProducts: true,
      toggler: false,
      sources: [] as string[],
      sourcesIndex: -1,
      sourcesSlide: 1,
      customer: [] as TaggingItem[],
      merchant: [] as TaggingItem[],
      channel: [] as ChannelOption[],
      channelLoading: false,
    });

    watch(
      () => props.show,
      (newValue) => {
        if (newValue) {
          showModal(chooseProduct.value);
          isHide.value = false;
        } else if (!isHide.value) {
          hideModal(chooseProduct.value);
        }
      }
    );

    const handleSelectionChange = (selection) => {
      formData.value.stock_in_ids = selection.map((item) => {
        return item.id;
      });
    };

    const merchantChange = (e) => {
      options.value.channel = [];
      formData.value.channel = "";
      options.value.channelLoading = false;
      if (e !== "") {
        getSalesChannelsData(e);
      }
    };

    const getMerchantSourceData = async (value, id?) => {
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData({
        ...params,
        options: { include_fields: ["name", "country_iso_2", "address"] },
      });
      if (data.code == 0) {
        options.value.merchant = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const getSalesChannelsData = async (id?: NumberOrString) => {
      options.value.channelLoading = true;
      const { data } = await ApiMerchant.getSalesChannelsData({
        max_item: "all",
        merchant_id: id,
        status: "10",
      });
      options.value.channelLoading = false;
      if (data.code == 0) {
        options.value.channel = data.data;
      }
    };

    const previewImages = (
      previewImages: ProductItemImage[] | undefined,
      imageIndex: number
    ) => {
      options.value.sources = previewOrderImages(previewImages);
      options.value.sourcesIndex = imageIndex;
      options.value.sourcesSlide = imageIndex + 1;
      options.value.toggler = !options.value.toggler;
    };

    const isDisabledSubmit = computed(() => {
      const stock_in_ids: any = formData.value.stock_in_ids;
      if (stock_in_ids.length > 0 && stock_in_ids.length <= 1) {
        return false;
      }
      return true;
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          addStockIn();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const addStockIn = async () => {
      loading.value = true;
      const { data } = await ApiStock.addStockIn({
        sku_identifier: formData.value.stock_in_ids.toString(),
        merchant: formData.value.merchant,
        channel: formData.value.channel,
        ...props.submitData,
      });
      loading.value = false;
      submitButton.value?.removeAttribute("data-kt-indicator");
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          handleDiscard();
          emit("update-list");
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const getShowInfo = async () => {
      const { data } = await ApiStock.searchPimProduct(props.submitData);
      if (data.code == 0) {
        options.value.list = data.data.items;
      }
    };

    const getFormInfo = async () => {
      loading.value = true;
      Promise.all([getShowInfo(), getMerchantSourceData("")])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    const handleDiscard = () => {
      resetForm();
      if (!props.show) hideModal(chooseProduct.value);
    };

    const modalShowListener = (modalEl: HTMLElement | null, callback): void => {
      if (!modalEl) {
        return;
      }
      modalEl.addEventListener("show.bs.modal", callback);
    };

    onMounted(() => {
      modalShowListener(chooseProduct.value, () => {
        isHide.value = false;
        getFormInfo();
      });
      modalHideListener(chooseProduct.value, () => {
        isHide.value = true;
        resetForm();
      });
    });

    return {
      t,
      formatDateTime,
      props,
      chooseProduct,
      loading,
      formData,
      submitButton,
      formRef,
      options,
      rules,
      submit,
      resetForm,
      handleSelectionChange,
      merchantChange,
      handleDiscard,
      isDisabledSubmit,
      previewImages,
      searchMerchantSourceItems,
    };
  },
});
