
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiBase } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import ChooseProductModal from "./ChooseProduct.vue";
import { modalKeyDownListener } from "@/core/directive/function/common";
import { TaggingItem } from "@/core/directive/interface/common";
import _ from "lodash";

export default defineComponent({
  name: "add-stock-in-modal",
  components: {
    ChooseProductModal,
  },
  props: {
    option: { type: Object, required: true },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const addStockInModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);
    const showChooseProduct = ref<boolean>(false);

    const { showValidateErrorMsg } = mixin();

    const formData = ref({
      warehouse: "",
      inventory_target: "",
      stock_in_quantity: 0,
      brand_id: "",
      brand_article_no: "",
      color: "",
      size: "",
    });

    const options = ref({
      warehouse: [],
      inventory_target: [],
      brand: [] as TaggingItem[],
      brandLoading: false,
    });

    const rules = ref({
      warehouse: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      inventory_target: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      stock_in_quantity: [
        {
          required: true,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (String(value).trim() == "" || value <= 0) {
                reject(t("common.plsPositiveInteger"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      brand_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      brand_article_no: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    watch(
      () => formData.value.stock_in_quantity,
      (newValue) => {
        formData.value.stock_in_quantity = Number(
          String(newValue).replace(/[^\d]/g, "")
        );
      }
    );

    const getBrandSourceData = async (value, id?) => {
      options.value.brandLoading = true;
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getBrandSourceData(params);
      options.value.brandLoading = false;
      if (data.code == 0) {
        options.value.brand = data.data;
      }
    };

    const debounceBrandSourceSearch = _.debounce(getBrandSourceData, 1000);

    const searchBrandSourceItems = (query: string) => {
      debounceBrandSourceSearch(query);
    };

    const getFromInfo = () => {
      Promise.all([])
        .then(() => {
          // loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      resetForm();
      hideModal(addStockInModalRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    const resetShow = () => {
      showChooseProduct.value = false;
    };

    const updateList = () => {
      handleDiscard();
      emit("update-list");
    };

    const isDisabledSubmit = computed(() => {
      if (
        formData.value.warehouse == "" ||
        formData.value.inventory_target == "" ||
        formData.value.stock_in_quantity <= 0 ||
        String(formData.value.stock_in_quantity) == "" ||
        formData.value.brand_id == "" ||
        formData.value.brand_article_no == ""
      ) {
        return true;
      }
      return false;
    });

    const modalShowListener = (modalEl: HTMLElement | null, callback): void => {
      if (!modalEl) {
        return;
      }
      modalEl.addEventListener("show.bs.modal", callback);
    };

    onMounted(() => {
      modalShowListener(addStockInModalRef.value, () => {
        modalKeyDownListener(addStockInModalRef.value, (e) => {
          if (e.key == "Enter" && !isDisabledSubmit.value) {
            showChooseProduct.value = true;
          }
        });
      });
    });

    return {
      t,
      props,
      loading,
      showChooseProduct,
      formData,
      rules,
      formRef,
      addStockInModalRef,
      submitButton,
      options,
      searchBrandSourceItems,
      isDisabledSubmit,
      submit,
      handleDiscard,
      resetForm,
      updateList,
      resetShow,
    };
  },
});
